<template>
  <div>

    <div class="header-nav">
      <img src="/images/logo1.png" alt="Seekrite.com" class="logo">
      <nav>
        <a href="#" @click="$scrollTo('#mobile-app')">Mobile App</a>
        <a href="/vpn">VPN </a>
        <a href="privacy-policy">Privacy Policy</a>
      </nav>
      <div v-if="showPopup" class="popup" id="popup">
        <h2>Make <span>Seekrite</span>
          <svg v-on:click="showPopup = false" width="16" height="16" viewBox="0 0 16 16" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
                d="M15.5248 13.3069C16.1584 13.9406 16.1584 14.8911 15.5248 15.5248C15.2079 15.8416 14.8119 16 14.4158 16C14.0198 16 13.6238 15.8416 13.3069 15.5248L8 10.2178L2.69307 15.5248C2.37624 15.8416 1.9802 16 1.58416 16C1.18812 16 0.792079 15.8416 0.475247 15.5248C-0.158416 14.8911 -0.158416 13.9406 0.475247 13.3069L5.78218 8L0.475247 2.69307C-0.158416 2.05941 -0.158416 1.10891 0.475247 0.475247C1.10891 -0.158416 2.05941 -0.158416 2.69307 0.475247L8 5.78218L13.3069 0.475247C13.9406 -0.158416 14.8911 -0.158416 15.5248 0.475247C16.1584 1.10891 16.1584 2.05941 15.5248 2.69307L10.2178 8L15.5248 13.3069Z"
                fill="#2341A2" />
          </svg>
        </h2>
        <h3>Your Default Search Engine</h3>
        <p>By Clicking the Button Below</p>
        <button v-if="$browserDetect.isChrome || $browserDetect.isChromeIOS" id="chrome">
          <img src="/images/chrome.svg" alt="">
          Add Seekrite to Chrome
        </button>
        <button v-if="$browserDetect.isEdge" id='edge'>
          <img src="/images/edge.svg" alt="">
          Add Seekrite to Edge
        </button>
        <button v-if="$browserDetect.isSafari || $browserDetect.isIOS" id="safari">
          <img src="/images/safari.svg" alt="">
          Add Seekrite to Safari
        </button>
        <button v-if="$browserDetect.isFirefox" id="firefox">
          <img src="/images/firefox.svg" alt="">
          Add Seekrite to Firefox
        </button>
      </div>
    </div>

    <header>
      <div class="search-container">
        <div class="search">
          <img class="search-logo" src="/images/logo2.png" alt="Seekrite" ref="">
          <h3>UNCENSORED PRIVATE SEARCH</h3>
          <search-input/>
          <p>Protecting Your Privacy</p>
        </div>
      </div>
    </header>

    <section>
      <div class="section-block">
        <img src="/images/image1.svg" alt="">
        <div>
          <h2>No Retargeting</h2>
          <p>Tired of being hassled to buy items you’ve previously searched? Seekrite doesn’t log your IP
            address or searches. We also don’t place cookies on your computer. None of your data is sold to
            advertisers or used to retarget you..</p>
        </div>
      </div>

    </section>
    <section>
      <div class="section-block">
        <div>
          <h2>How Do We Make Money?</h2>
          <p>We don't make money by selling your data or information about your personal interests or search
            habits, because we don't keep your data. We simply make money if you click on ads or when you
            shop on our site.</p>
        </div>
        <img src="/images/image2.svg" alt="">
      </div>

    </section>
    <section>
      <div class="section-block">
        <img src="/images/image3.svg" alt="">
        <div>
          <h2>Seekrite-Uncensored Search and Information You Can’t Otherwise Easily Find</h2>
          <p>Other search engines censor their search information, similar to how the media censors news it
            doesn't want to show you.
            Seekrite offers uncensored, private search which shows you more search results to keep you
            broadly informed.</p>
        </div>
      </div>

    </section>

    <section>
      <div class="section-block mobile-app">
        <h2 id="mobile-app">Seekrite Mobile App</h2>
        <p>With the Seekrite Mobile App you can comfortably use the internet anonymously without a concern of
          there being a record of the sites you visited or the searches you made. Seekrite uses the Wormhole
          browser-less application which allows you to visit sites which are viewable right through the app.</p>
        <div class="app-links">
          <a>
            <i class="p-handler"></i>
            <p>Download iPhone App</p>
            <img src="/images/apple.svg" alt="">
          </a>
          <a>
            <i class="p-handler"></i>
            <p>Download Android App</p>
            <img src="/images/android.svg" alt="">
          </a>
        </div>
      </div>

    </section>
    
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput";

export default {
  name: "Home",
  components: { SearchInput },
  data:() => ({
    showPopup: false
  })
};
</script>

<style lang="scss" scoped>

div, h1, h2, h3, a, footer, header, nav, section {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

div, section {
  box-sizing: border-box;
}

footer, header, nav, section {
  display: block;
}

body * {
  font-family: 'Inter', sans-serif;
  line-height: 1;
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

body>* {
  box-sizing: border-box;
}

a {
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-decoration: none;
  color: #000000;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 61px;
  margin-bottom: 0.5em;
}

p {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  //background-image: url('/images/header-bg.png');
  background: linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), url('/images/header-bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 680px;
}

.header-nav {
  position: absolute;
  top: 0;
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 130px;
  background-color: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}

.header-nav nav {
  display: flex;
  justify-content: space-between;
  width: 300px;
}

.popup {
  visibility: visible;
  box-sizing: border-box;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  background-image: url('/images/popup-bg.svg');
  background-position: center;
  bottom: 0px;
  right: 130px;
  padding: 25px 15px;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  svg {
    position: absolute;
    right: 0;
    top: -5px;
    cursor: pointer;
  }
  >h2 {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #202D51;
    position: relative;
  }
  >h3 {
    margin-top: -10px;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;
    color: #202D51;
  }
  >p {
    margin-top: 1em;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #202D51;
  }
  >button {
    display: flex;
    align-items: center;
    width: 220px;
    height: 40px;
    background: #202D51;
    border-radius: 53px;
    border: none;
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
    line-height: 26px;
    cursor: pointer;
    margin-top: 10px;
  }
  img {
    margin-right: 5px;
  }
}

.logo {
  width: 130px;
  height: 70px;
}
.search-container {
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search-logo {
  width: 270px;
  height: 90px;
  position: sticky;
}

.search h3 {
  margin: 30px 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #202D51;
}

.search p {
  margin: 30px 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5)
}

section {
  width: 100%;
  min-height: 675px;
  color: #202D51;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-block {
  width: 1110px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

section:nth-child(4) {
  background: linear-gradient(109.46deg, #F0FCFF 30.68%, #FAF9FB 65.26%, #FEF8F9 89.71%,
                              #FDF9FA 114.15%, #FFF8F9 133.83%, #FFF8F9 145.16%);
}

.section-block div {
  width: 475px;
}

section:nth-child(6) {
  color: #ffffff;
  background-color: #2341A2;
}

.mobile-app {
  flex-direction: column;
  justify-content: center;
  height: 720px;
}

.mobile-app h2 {
  margin: 100px 0 40px;
}

.mobile-app>p {
  width: 870px;
  text-align: center;
  margin-bottom: 50px;
}

div.app-links {
  display: flex;
  justify-content: space-between;
  width: 700px;
  margin-bottom: 100px;
}

.app-links a {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  color: #ffffff;
  p {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.p-handler {
  display: block;
  height: 132px;
  width: 132px;
  background-color: #ffffff;
  background-image: url('/images/p.svg');
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  -ms-border-radius: 24px;
  -o-border-radius: 24px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: rgba(35, 65, 162, 0.5)
}

@media screen and (max-width: 1500px) {
  .header-nav nav{
    display: none;
  }
}
@media (max-width: 1200px) {
  .section-block {
    padding: 0px 20px;
  }
  .section-block >div{
    width: 80vw;

  }
  .section-block h2{
    text-align: center;
  }
  .section-block>img {
    max-width: 40vw;
    height: unset;
  }
  .section-block >div{
    max-width: 50vw;
  }
}
@media (max-width: 900px) {
  .mobile-app >p {
    width: 80vw;
  }
  .app-links {
    width: 80vw !important;

    a p {
      font-size: 16px;
    }
  }
}

@media (max-width: 700px) {
  .header-nav {
    padding: 2vw;
  }
  .search-input input {
    max-width: 80vw;
    padding-left: 100px;
  }
  .section-block {
    flex-direction: column;
    padding: 20px 0;
  }
  .section-block >div{
    width: 100%;
    padding: 20px;
    max-width: unset;
  }
  .section-block h2{
    text-align: center;
  }
  .section-block>img {
    max-width: 80vw;
    height: unset;
    margin-bottom: 20px;
  }
  section:nth-child(2)>.section-block {
    flex-direction: column-reverse;
  }
  .mobile-app {
    width: 100%;
    padding: 0 20px;
    height: fit-content;
    height: -moz-fit-content;
    height: -webkit-fit-content;
  }
  .mobile-app >p {
    width: 100%;
  }
  .app-links {
    flex-direction: column;
  }
  .app-links :first-child {
    margin-bottom: 40px;
  }
  .app-links a p {
    margin-top: 0;
  }

  .popup {
    display: none;
  }

}

@media (max-width: 550px) {
  .header-nav>nav {
    margin-left: 10px;
    width: 66vw;
  }
}

</style>
