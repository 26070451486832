<template>
  <div class="header-nav">
    <nav class="desktop-menu is-flex">
      <a href="/#mobile-app">Mobile App</a>
      <a href="/vpn">VPN </a>
      <a href="privacy-policy">Privacy Policy</a>
    </nav>

    <div class="mobile-menu-button">
      <b-icon
          :class="linkClass"
          icon="menu"
          size="is-large"
          @click.native="showNavPanel = true">
      </b-icon>
    </div>
    <b-sidebar
        type="is-primary"
        :fullheight="true"
        :fullwidth="false"
        :overlay="false"
        :right="true"
        v-model="showNavPanel"
    >

      <nav class="mobile-menu">
        <div class="has-text-right">

          <b-icon
              class="has-text-white"
              icon="close"
              size="is-medium"
              aria-label="close"
              @click.native="showNavPanel = false">
          </b-icon>

        </div>
        <ul>
          <li class="menu-item mb-5" v-for="(menuItem, i) in menuItems" :key="i">

            <router-link
                @click.native="showNavPanel = false"
                class="menu-item-link has-text-white"
                :class="[i === menuItems.length-1 ? 'last': '']"
                :to="menuItem.link">{{menuItem.text}}
            </router-link>

          </li>
        </ul>
      </nav>
    </b-sidebar>
  </div>
</template>

<script>
export default {
    name: "SiteMenu",
    props: {
      linkClass: {
        type: String,
        default: "is-secondary"
      }
    },
    data: () => ({
      menuItems:[
        {text: 'Privacy', link: '/privacy-policy'},
        {text: 'Mobile', link: '/#mobile-app'},
        {text: 'VPN', link: '/vpn'},
      ],
      showNavPanel: false
    })
}
</script>

<style lang="scss" scoped>

.header-nav {
  display: flex;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 100px;
  font-size: 16px;
}

.header-nav nav {
  justify-content: space-between;
  width: 300px;

  font: inherit;
  font-family: 'Inter', sans-serif;

  a {
    color: #000;
  }

}

.mobile-menu-button{
  display: none;
  margin-right: -80px;
}
.mobile-menu{
  padding: 20px;
}
.mobile-menu .menu-item-link{
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: -15px;
}

@media screen and (max-width:1500px){
  .desktop-menu{
    display: none;
    a {
      font-size: 0;
    }
  }
  .mobile-menu-button{
    display: flex;
  }
}
</style>