<template>
    <nav v-scroll="handleScroll" role="banner" :class="{'is-minimal': isMinimal}" class="navbar is-fixed-top top-bar is-align-items-center">
        <a href="/"><img class="logo" src="/images/logo2.png" alt="Seekrite"></a>
        <search-input/>
        <site-menu link-class="has-text-primary" class="top-site-menu" />
    </nav>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import SiteMenu from "@/components/SiteMenu";
export default {
    name: "TopBar",
    components: { SearchInput, SiteMenu },
    data: () => ({
        isMinimal: false
    }),
    methods: {
        handleScroll(evt, el) {
            if (window.scrollY > 80) {
                this.isMinimal = true;
            }else{
                this.isMinimal = false;
            }
            return false;
        }
    },    
}
</script>

<style lang="scss" scoped>
    //@import '@/assets/scss/_vars.scss';
    .logo{
        width: 223px;
        max-width: 223px;
        height: auto;
        position: relative;
        top: 5px;
        padding-right: 50px;
    }
    .search-input{
        width: 616px;
        padding-right: 30px;
    }
    .top-bar{
        display: flex;
        //background-image: url('/images/header-bg.png');
        background: linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.04)), url('/images/header-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
        transition: all 200ms ease-out;
        box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.2);
        padding: 40px 0;
        &.is-minimal{
            padding-top: 10px;
            padding-bottom: 10px;
        }
        img {
          margin-left: 40px;
        }
    }
    .top-site-menu{
        margin-left: auto;
    }
    @media screen and (max-width: 768px){
        .top-bar{
            padding: 30px;
            display: block;
            box-shadow: 1px 1px 5px 2px rgba(0,0,0,0);
        }
        .search-input{
            width: 100%;
        }
        .top-site-menu{
            position: absolute;
            top: 20px;
            right: 20px;
        }
        .logo{
            display: block;
            width: 170px;
            margin-bottom: 28px;
        }
        .logo, .search-input{
            padding-right: 0;
        }
    }
</style>