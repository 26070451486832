<template>
  <!-- @submit.prevent="submit" -->
  <form :action="formAction">
    <b-field>
      <label>
        <input
          v-model="query"
          class="search-input"
          name="q"
          placeholder="Start typing..."
          autocomplete="off"
        />
      </label>
      <!--        :icon-right="this.query ? `close-circle`: ``"-->
<!--        icon-right-clickable-->
<!--        @icon-right-click="clearIconClick"-->
<!--      />-->

<!--      <p class="control">-->
<!--        <b-button-->
<!--          size="is-hidden-desktop"-->
<!--          class="search-button-top"-->
<!--          native-type="submit"-->
<!--          type="is-primary"-->
<!--          icon-right="magnify"-->
<!--        />-->
        <b-button class="search-button" native-type="submit">
          <i class="search-icon"/>
        </b-button>
<!--      </p>-->
    </b-field>
  </form>
</template>

<script>
export default {
  name: "SearchInput",
  data: () => ({
    query: null,
  }),
  methods: {
      clearIconClick(){
          this.query = null;
      }
    /*
        submit(){
            let searchType = this.$route.params.searchType || null;
            let query = this.query;
            //Queryless searching could be possible by omitting the query object below
            this.$router.push({ name: 'Results', params: { query, searchType }, query: { q: query } })
            //this.$router.push(`/results/${searchType}?q=${query}`)
        }
        */
  },
  watch: {
    $route() {
      if (this.$route.query.q) {
        this.query = this.$route.query.q;
      }
    },
  },
  computed: {
    formAction() {
      let searchType = this.$route.params.searchType || "";
      return `/results/${searchType}`;
    },

    /*query:{
            get(){
                return this.$store.state.query;
            },
            set(value){
                this.$store.commit('setQuery', value);
            }
        },*/
  },
};
</script>

<style lang="scss" scoped>


.field {
  position: relative;
}

.search-input {
  width: 1120px;
  max-width: 65vw;
  height: 70px;
  border: none;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  padding: 0 70px 0 50px;
  border-radius: 83px;
  outline: none;
  font-size: 20px;
}

.search-button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  height: 56px;
  width: 56px;
  background-color: #1A7D98;
  border-radius: 50%;
  outline: none;
  border: none;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.search-icon {
  display: block;
  height: 20px;
  width: 20px;
  background: url('/images/search.svg') no-repeat center;
}


</style>