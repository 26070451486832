<template>
  <footer class="footer content has-text-centered">

    <div class="m-3">
      <div>&copy; {{ year }} {{ siteName }} - {{description}} </div>
    </div>
    
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  data: () => ({
    year: new Date().getFullYear(),
    siteName: process.env.VUE_APP_SITE_TITLE,
    description: process.env.VUE_APP_META_DESC,
  }),
};
</script>

<style lang="scss" scoped>
footer{
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 17px;
    line-height: 40px;
    text-align: center;
    color: rgba(35, 65, 162, 0.5);
    @media screen and (max-width: 800px){
        display: block;
    }
}
</style>